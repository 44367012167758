.card {
  .card-body .card-text {
    color: $text-color;
  }

  .categoryicon-top .text,
  .categoryicon-top .data,
  .etichetta {
    color: $tertiary;
  }

  .categoryicon-top .icon {
    fill: $tertiary;
  }

  a.read-more {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.card-teaser {
    .card-title a {
      color: $link-color;
    }

    .categoryicon-top .icon {
      color: $tertiary !important;
      fill: $tertiary !important;
    }
  }

  .etichetta .icon {
    fill: $tertiary;
  }
}
