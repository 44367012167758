.it-footer {
  &-main,
  &-small-prints {
    background-color: $footer-bg-color;
  }

  .it-footer-main {
    .it-brand-wrapper {
      a .icon {
        width: auto;
        height: 70px;
        margin-right: 1rem;

        img {
          height: 100%;
        }
      }

      .it-brand-text {
        display: none;
      }
    }
  }
}

&.subsite {
  .it-footer {
    &-main {
      .it-brand-wrapper {
        .it-brand-text {
          display: block;
        }
      }
    }
  }
}
