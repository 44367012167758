.bando-view {
  .bando_state {
    &.open {
      border-left-color: $open-color;

      svg {
        fill: $open-color;
      }
    }

    &.closed {
      border-left-color: $closed-color;

      svg {
        fill: $closed-color;
      }
    }
  }
}
