.navbar {
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .navbar-collapsable {
      &.expanded {
        .menu-wrapper {
          .it-brand-wrapper {
            background-color: $primary;
            padding-top: 1rem;
            padding-bottom: 0.5rem;

            a .icon {
              height: 66px;
              width: 100%;

              img {
                height: 100%;
              }
            }
          }
        }
      }

      .it-socials {
        ul {
          li a .icon {
            fill: $primary;
          }
        }
      }
    }
  }
}
