.it-header-center-wrapper {
  .it-header-center-content-wrapper .it-brand-wrapper {
    a {
      width: 100%;

      .icon {
        width: auto;
        height: 108px;
        margin-bottom: 0;

        img {
          max-width: inherit;
          height: 100%;
        }
      }
    }

    .it-brand-text {
      display: none;
    }
  }
}

&.staging-site .it-header-center-wrapper,
&.staging-site .it-header-navbar-wrapper {
  background-color: $header-center-bg-color-staging;
}

&.subsite {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper .it-brand-wrapper {
      .it-brand-text {
        display: block;
      }
    }
  }
}

@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 1.5rem;
  }

  .it-header-center-wrapper {
    .it-header-center-content-wrapper .it-brand-wrapper a .icon {
      height: 66px;
    }
  }

  .navbar .navbar-collapsable .menu-wrapper .it-brand-wrapper .it-brand-text {
    display: none;
  }
}

@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper {
    border-top: 1px solid $secondary-text;
    margin-top: 1rem;
  }

  .it-header-center-wrapper {
    padding-top: 0.5rem;
    padding-left: 1rem;

    .it-header-center-content-wrapper .it-brand-wrapper a {
      .icon {
        width: auto;
        height: 108px;
        margin-bottom: 0;
      }
    }
  }
}
