//site
$primary-h: 210 !default;
$primary-s: 74 !default;
$primary-b: 39 !default;

$primary: #1a3f64;
$tertiary: #95700f;
$tertiary-dark: #72560c;

//Titles and Text
$secondary-text: #fff;
$tertiary-text: #fff;
$headings-color: $primary;

//Link and Buttons
$link-color: $primary;

//Header
$site-header-slim-bg-color: $tertiary;
$header-center-bg-color: $primary;
$header-center-bg-color-staging: #02660b;

// Footer
$footer-bg-color: $primary;

//Background
$light: #f4f7f9;

//Etichette Bandi
$open-color: #008000;
$closed-color: #ed0202;
