.contentInEvidenceTemplate {
  .content-in-evidence {
    .card-title {
      a {
        color: $link-color;
      }
    }
    &:nth-of-type(2n) {
      background-color: $light;
    }
  }
}
