.block.highlitedContent {
  .card-title a {
    color: $link-color;
  }

  .bg-primary,
  .bg-secondary {
    .category-top .data,
    .categoryicon-top .data {
      color: $secondary-text !important;
    }
  }
}
