// buttons
.btn-tertiary,
a.btn-tertiary,
&.subsite .btn-tertiary,
&.subsite a.btn-tertiary {
  @include button-outline-variant($tertiary-dark, $tertiary-dark);
  color: $tertiary-dark !important;

  svg {
    fill: $tertiary-dark;
  }

  &:hover,
  &:active {
    color: $secondary-text !important;

    svg {
      fill: $secondary-text !important;
    }
  }
}

.btn-primary,
a.btn.btn-primary {
  @include button-variant($tertiary, $tertiary);

  svg {
    fill: $secondary-text;
  }

  &:hover,
  &:active {
    color: $secondary-text !important;

    svg {
      fill: $secondary-text !important;
    }
  }
}

// icons
.icon-argument-container .icon,
.icon-argument-container svg {
  fill: $tertiary;
  color: $tertiary;
}

//draftJS
.callout-bg {
  background-color: $secondary;
  color: $secondary-text;
}
