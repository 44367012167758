.bandi-in-evidence {
  .title a.bando-title {
    color: $link-color;
    font-size: 1.3rem;
    line-height: 1.5rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .bando-dati .bando-dati-info {
    flex-direction: column;

    .bando-dati-label {
      color: $primary;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }

  .bando-state {
    margin-top: 0.4rem;
  }

  .open {
    background-color: $open-color;
  }

  .closed {
    background-color: $closed-color;
  }

  .card a.read-more {
    width: calc(100% - 5rem);
    bottom: 10px;
    right: 2.5rem;
    left: 2.5rem;
  }
}
