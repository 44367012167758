.ribbon-card-template {
  .card {
    .card-body {
      .card-title a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.card-bg {
      .flag-icon {
        background-color: $tertiary;
      }
    }
  }
}
